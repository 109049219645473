import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import IntlMessages from "../../../@crema/utility/IntlMessages";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Fonts } from "../../../shared/constants/AppEnums";
import TextField from "@material-ui/core/TextField";
import { hideAuthorizationDialog } from "../../../redux/actions/SSHepApp";
import { onJwtSignIn, setJWTToken } from "../../../redux/actions";
import { authProviders } from "../../../shared/constants/AppConst";
import { SET_API_TOKEN } from "../../../shared/constants/ActionTypes";

export const AuthorizationDialog = () => {
  const dispatch = useDispatch();
  let userItem = localStorage.getItem("user");
  let userParsed = userItem ? JSON.parse(userItem) : null;
  const theApp = useSelector(({ sshepApp }) => sshepApp);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [userValues, setUserValues] = React.useState({
    email: userParsed?.username, //"user@company.com",
    password: "",
  });
  const [dialogOpen, setDialogOpen] = React.useState(false);

  useEffect(() => {
    if (!window.opener) {
      // The main window (not the one-shot SSO popup)
      window.addEventListener("message", function (event) {
        // Accept messages from windows in the same origin (base URL)
        if (event.origin != window.location.origin) return;
        //Main window received new message
        if (event.data.type == "newAuthToken") {
          let newAuthToken = event.data.data;
          storeNewToken(newAuthToken);
          dispatch({ type: SET_API_TOKEN, payload: newAuthToken });
        }
      });
    }
  }, []);

  const handleChange = (name) => (event) => {
    setUserValues({ ...userValues, [name]: event.target.value });
  };

  useEffect(() => {
    setDialogOpen(theApp?.sshepherdAuthorizationDialogOpen);
  }, [theApp?.sshepherdAuthorizationDialogOpen]);

  const useStyle = makeStyles({
    btn: {
      marginLeft: 8,
      fontWeight: Fonts.MEDIUM,
    },
    contentText: {
      color: "#FFF",
    },
    textField: {
      width: "240px",
    },
  });
  const classes = useStyle();

  function handleKeyDown(event) {
    if (
      event.key === "Enter" &&
      userValues?.email?.length > 0 &&
      userValues?.password?.length > 0
    ) {
      onConfirm();
    }
  }

  const onDeny = () => {
    setUserValues({ ...userValues, password: "" });
    dispatch(hideAuthorizationDialog());
  };

  const storeNewToken = (newAuthToken) => {
    if (newAuthToken) {
      let ss = localStorage.getItem("sshepherdSecurity");
      let ssParsed = ss ? JSON.parse(ss) : null;
      if (ssParsed?.api_limit_single_use == true) {
        localStorage.setItem("oneshotToken", newAuthToken);
      } else if (ssParsed?.api_limit_freshness == true) {
        localStorage.setItem("token", newAuthToken);
      }
    }
  };

  const onConfirm = async () => {
    document.body.classList.add("wait");
    setIsSubmitting(true);

    await dispatch(
      onJwtSignIn({
        email: userValues.email,
        password: userValues.password,
        authType: theApp.forceStandardLogin
          ? authProviders.PASSWORD
          : theApp.authProvider,
        callback: (result) => {
          document.body.classList.remove("wait");

          if (result?.status == 200) {
            storeNewToken(result.data?.response?.user?.authentication_token);
            setUserValues({ ...userValues, password: "" });
            dispatch(hideAuthorizationDialog());

            if (theApp?.sshepherdAuthorizationDialogCallback != null) {
              theApp.sshepherdAuthorizationDialogCallback(true);
            }
          } else if (result?.status == 400) {
            if (result?.data?.response?.errors?.length > 0) {
              result.data.response.errors.forEach((e) => {
                alert(e);
              });
            }
            if (theApp?.sshepherdAuthorizationDialogCallback != null) {
              theApp.sshepherdAuthorizationDialogCallback(false);
            }
          } else {
            if (theApp?.sshepherdAuthorizationDialogCallback != null) {
              theApp.sshepherdAuthorizationDialogCallback(false);
            }
          }
          setIsSubmitting(false);
        },
        isOneShot: true,
      })
    );
  };

  return (
    <Dialog open={dialogOpen} onClose={() => onDeny(false)}>
      <Box
        width={300}
        px={{ xs: 5, md: 7 }}
        pt={{ xs: 4, md: 6 }}
        pb={{ xs: 2, md: 4 }}
      >
        <Box
          mb={3}
          component="h4"
          fontWeight={Fonts.MEDIUM}
          className="font-bold"
          id="alert-dialog-title"
        >
          {"Authorize"}
        </Box>
        <Box>
          <DialogContentText
            className={classes.contentText}
            id="alert-dialog-description"
          >
            {/*"Title here"*/}
          </DialogContentText>

          <Box>
            <TextField
              id="standard-name"
              label="User Email"
              className={classes.textField}
              autoFocus
              required={true}
              value={userValues.email}
              onChange={handleChange("email")}
              margin="normal"
              onFocus={(event) => {
                event.target.select();
              }}
            />
          </Box>
          <Box>
            <TextField
              id="standard-password"
              label="User Password"
              type="password"
              className={classes.textField}
              required={true}
              value={userValues.password}
              onChange={handleChange("password")}
              onKeyDown={handleKeyDown}
              margin="normal"
              onFocus={(event) => {
                event.target.select();
              }}
            />
          </Box>
        </Box>

        <Box pt={2} align="right">
          <Button
            variant="contained"
            className={classes.btn}
            onClick={() => onDeny(false)}
          >
            <IntlMessages id="common.cancel" />
          </Button>

          <Button
            variant="contained"
            disabled={!userValues.password || !userValues.email || isSubmitting}
            className={classes.btn}
            onClick={() => onConfirm(userValues)}
            color="primary"
          >
            <IntlMessages id="common.Authorize" />
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AuthorizationDialog;
