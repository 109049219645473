
import React, {useState} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const HeaderDataToggle = (props) => {
  // Save a reference to the mock adapter so that we can later reuse it
  
  const [useMockData, setUseMockData] = useState( localStorage.getItem('useMockData') );

  return (
    <>
      <FormControlLabel
        control={
          <Switch checked={useMockData} onChange={ (e) => { 
              let newUseMockData = !useMockData;

              setUseMockData( newUseMockData ) 
              localStorage.setItem('useMockData', newUseMockData == true);
              window.location.reload();
            }
          } /> 
        }
        label='Use Mock Data'
      />
    </>
  );
};

export default HeaderDataToggle;


