import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const groupsConfigs = [
  {
    auth: ["admin"],
    routes: [
      {
        path: '/groups',
        component: React.lazy(() => import('./Groups')),
      },
    ],
  },

];
