import React from "react";
import { authRole } from "../shared/constants/AppConst";
import { useSelector } from "react-redux";

import { ReactComponent as SessionIcon } from "assets/images/swap-horiz.svg";
import { ReactComponent as DashboardIcon } from "assets/images/dashboard.svg";
import { ReactComponent as HostIcon } from "assets/images/dns.svg";
import { ReactComponent as UserIcon } from "assets/images/user.svg";
import { ReactComponent as GroupIcon } from "assets/images/group.svg";
import { ReactComponent as RoleIcon } from "assets/images/security.svg";
import KeyIcon from "@mui/icons-material/KeyOutlined";

const routesConfig = [
  {
    id: "sessions",
    title: "Sessions",
    messageId: "sidebar.Sessions",
    type: "item",
    //count: 12,
    dynamicCount: "totalSessions",
    badgeColor: "transparent", //'#0078D7',
    badgeContentColor: "#FFF",
    dynamicCount2: "liveSessions",
    badgeColor2: "#E66", //'#bb6838',
    badgeContentColor2: "#FFF",
    //icon: 'swap_horiz', // don't use cached icons from the web (these don't work for installations that are not connected to the internet)
    iconObject: <SessionIcon />, // use a local icon object
    auth: ["admin"],
    url: "/recordings",
  },
  {
    id: "hostsdashboard",
    title: "Dashboard",
    messageId: "sidebar.HostsDashboard",
    type: "item",
    dynamicCount: "totalHosts",
    badgeColor: "transparent", //'#0078D7',
    badgeContentColor: "#FFF",
    //icon: 'dashboard', // don't use cached icons from the web (these don't work for installations that are not connected to the internet)
    iconObject: <DashboardIcon />, // use a local icon object
    auth: ["admin", "user"],
    url: "/hostsdashboard",
  },
  {
    id: "hosts",
    title: "Hosts",
    messageId: "sidebar.Hosts",
    type: "item",
    dynamicCount: "totalHosts",
    badgeColor: "transparent", //'#0078D7',
    badgeContentColor: "#FFF",
    //icon: 'dns', // don't use cached icons from the web (these don't work for installations that are not connected to the internet)
    iconObject: <HostIcon />, // use a local icon object
    auth: ["admin", "user"],
    url: "/hosts",
  },
  {
    id: "roles",
    title: "Roles",
    messageId: "sidebar.UserRoles",
    type: "item",
    //count: 4,
    dynamicCount: "totalRoles",
    badgeColor: "transparent", //'#0078D7',
    badgeContentColor: "#FFF",
    //icon: 'security', // don't use cached icons from the web (these don't work for installations that are not connected to the internet)
    iconObject: <RoleIcon />, // use a local icon object
    auth: ["admin"],
    url: "/roles",
  },
  {
    id: "groups",
    title: "Groups",
    messageId: "sidebar.Groups",
    type: "item",
    dynamicCount: "totalGroups",
    badgeColor: "transparent", //'#0078D7',
    badgeContentColor: "#FFF",
    //icon: 'group', // don't use cached icons from the web (these don't work for installations that are not connected to the internet)
    iconObject: <GroupIcon />, // use a local icon object
    auth: ["admin"],
    url: "/groups",
  },
  {
    id: "users",
    title: "Users",
    messageId: "sidebar.Users",
    type: "item",
    dynamicCount: "totalUsers",
    badgeColor: "transparent", //'#0078D7',
    badgeContentColor: "#FFF",
    //icon: 'person', // don't use cached icons from the web (these don't work for installations that are not connected to the internet)
    iconObject: <UserIcon />, // use a local icon object
    auth: ["admin"],
    url: "/users",
  },
  {
    id: "apiKeys",
    title: "Keys",
    messageId: "sidebar.Keys",
    type: "item",
    dynamicCount: "totalRegistrationKeys",
    //icon: "settings", // don't use cached icons from the web (these don't work for installations that are not connected to the internet)
    iconObject: <KeyIcon />, // use a local icon object
    auth: ["admin"],
    url: "/registrationKeys",
  },
  {
    id: "settings",
    title: "Settings",
    messageId: "sidebar.Settings",
    type: "item",
    icon: "settings", // don't use cached icons from the web (these don't work for installations that are not connected to the internet)
    //iconObject: <RoleIcon />, // use a local icon object
    auth: ["admin"],
    url: "/settings",
  },
];

export default routesConfig;
