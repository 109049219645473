import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { matchRoutes } from "react-router-config";
import AppContext from "./AppContext";
import { useAuthToken } from "./AppHooks";
import { Loader } from "../index";
import PropTypes from "prop-types";
import { checkPermission } from "./Utils";
import { initialUrl, fallbackUrl } from "../../shared/constants/AppConst";
import { setInitialPath } from "../../redux/actions";

const AuthRoutes = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { routes } = useContext(AppContext);

  const [loading, user] = useAuthToken();
  const initialPath = useSelector(({ settings }) => settings.initialPath);
  const currentRoute = matchRoutes(routes, pathname)[0].route;
  let isPermitted = checkPermission(currentRoute.auth, user ? user.role : null);

  useEffect(() => {
    function setInitPath() {
      if (
        initialPath === "/" &&
        [
          "/signin",
          "/signup",
          "/signout",
          "/confirm-signup",
          "/reset-password",
          "/error-pages/error-404",
          "/forget-password",
        ].indexOf(pathname) === -1
      ) {
        if (isPermitted) {
          dispatch(setInitialPath(pathname));
        } else {
          dispatch(setInitialPath(undefined));
        }
      }
    }

    setInitPath();
  }, [dispatch, isPermitted, initialPath, pathname]);

  useEffect(() => {
    if (!loading) {
      if (!user && !isPermitted) {
        history.push("/signin"); // allowed route
      } else if (user && !isPermitted) {
        history.push("/error-pages/error-404"); // Not found
      } else if (user && isPermitted) {
        if (
          pathname === "/" ||
          pathname === "/signin" ||
          pathname === "/signup" ||
          pathname === "/signout" ||
          pathname === "/signin?authprovider=1"
        ) {
          if (user) {
            if (user.role?.includes("admin")) history.push(initialUrl);
            else history.push(fallbackUrl);
          }
        } else if (
          initialPath &&
          initialUrl !== initialPath &&
          (initialPath !== "/" ||
            initialPath !== "/signin" ||
            initialPath !== "/signup" ||
            initialPath !== "/signout")
        ) {
          history.push(initialPath);
        }
      }
    }
  }, [user, loading, initialPath, isPermitted, pathname, history]);

  return loading ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};
