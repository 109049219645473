import axios from "axios";

const jwtAxios = axios.create({
  baseURL: '',
  headers: {
    "Content-Type": "application/json",
  },
});
jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.msg === "Token is not valid") {
      console.log("Need to logout user");
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  }
);
export const setAuthToken = (token) => {
  if (token) {
    jwtAxios.defaults.headers.common["x-auth-token"] = token;
    localStorage.setItem("token", token);
  } else {
    delete jwtAxios.defaults.headers.common["x-auth-token"];
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    console.log("setAuthToken - Clearing cookie");
    document.cookie =
      "session=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"; // Clear Cookie
  }
};

export default jwtAxios;
