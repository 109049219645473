import React, { useContext, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import { useDispatch } from "react-redux";
import {
  onCognitoUserSignOut,
  onJWTAuthSignout,
  onSignOutAuth0User,
  onSignOutFirebaseUser,
} from "../../../redux/actions";
import { useAuthUser } from "../../../@crema/utility/AppHooks";
import AppContext from "../../../@crema/utility/AppContext";
import IntlMessages from "../../../@crema/utility/IntlMessages";
import clsx from "clsx";
import { makeStyles, MenuItem } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import { orange } from "@material-ui/core/colors";
import { AuthType, Fonts } from "../../constants/AppEnums";
import ChangePasswordDialog from "../ChangePasswordDialog";
import { onEditUser } from "../../../redux/actions";

const useStyles = makeStyles((theme) => {
  return {
    crUserInfo: {
      backgroundColor: "rgba(0,0,0,.08)",
      paddingTop: 9,
      paddingRight: 0,
      paddingBottom: 9,
      minHeight: 56,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      [theme.breakpoints.up("sm")]: {
        paddingTop: 10,
        paddingBottom: 10,
        minHeight: 70,
      },
    },
    profilePic: {
      fontSize: 24,
      backgroundColor: orange[500],
    },
    userInfo: {
      width: "calc(100% - 75px)",
    },
    userName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontSize: 16,
      fontWeight: Fonts.MEDIUM,
      color: (props) => (props.themeMode === "light" ? "#313134" : "white"),
    },
    designation: {
      marginTop: -2,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: theme.palette.text.secondary,
    },
    pointer: {
      cursor: "pointer",
    },
  };
});

const UserInfo = (props) => {
  const { themeMode } = useContext(AppContext);
  const dispatch = useDispatch();
  const user = useAuthUser();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userName, setUserName] = React.useState("--");
  const [userRoleNames, setUserRoleNames] = React.useState("");
  const [isChangePasswordDialogOpen, setChangePasswordDialogOpen] =
    React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    formatUserInfo();
  }, []); //[dispatch, page, pathname]);

  const getUserAvatar = () => {
    if (user.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    }
    if (user.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };

  const formatUserInfo = () => {
    let user = localStorage.getItem("user");
    if (user) {
      try {
        let userObj = JSON.parse(user);
        let roleNames = "";
        setUserName(userObj.email);
        userObj.roles.forEach((r) => {
          if (roleNames) roleNames += ", ";
          roleNames += r.name;
        });
        setUserRoleNames(roleNames);
      } catch (ex) {
        setUserName("--");
        setUserRoleNames("");
      }
    } else {
      setUserName("--");
      setUserRoleNames("");
    }
  };

  const showChangePasswordDialog = () => {
    handleClose();
    setChangePasswordDialogOpen(true);
  };

  const handleChangePassword = (user) => {
    dispatch(onEditUser(user));
    setChangePasswordDialogOpen(false);
  };

  const classes = useStyles({ themeMode });

  return (
    <Box
      px={{ xs: 4, xl: 7 }}
      className={clsx(classes.crUserInfo, "cr-user-info")}
    >
      <Box display="flex" alignItems="center">
        {user.photoURL ? (
          <Avatar className={classes.profilePic} src={user.photoURL} />
        ) : (
          <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>
        )}
        <Box ml={4} className={clsx(classes.userInfo, "user-info")}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box mb={0} className={clsx(classes.userName)} title={userName}>
              {/*user.email ? user.email : 'Admin User '*/}
              {userName}
            </Box>
            <Box
              ml={1}
              className={classes.pointer}
              color={themeMode === "light" ? "#313134" : "white"}
            >
              <ExpandMoreIcon onClick={handleClick} />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => showChangePasswordDialog()}>
                  Change Password
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (user && user.authType === AuthType.AWS_COGNITO) {
                      dispatch(onCognitoUserSignOut());
                    } else if (user && user.authType === AuthType.FIREBASE) {
                      dispatch(onSignOutFirebaseUser());
                    } else if (user && user.authType === AuthType.AUTH0) {
                      dispatch(onSignOutAuth0User());
                    } else if (user && user.authType === AuthType.JWT_AUTH) {
                      dispatch(onJWTAuthSignout());
                    }
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <Box className={classes.designation} title={userRoleNames}>
            {userRoleNames}
          </Box>
        </Box>
      </Box>

      {isChangePasswordDialogOpen && (
        <ChangePasswordDialog
          open={isChangePasswordDialogOpen}
          onDeny={setChangePasswordDialogOpen}
          onConfirm={handleChangePassword}
          //title={<IntlMessages id='user.EditUserMessage' />}
          dialogTitle={<IntlMessages id="user.EditUser" />}
        />
      )}
    </Box>
  );
};

export default UserInfo;
