import React, { useContext } from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import clsx from "clsx";
import Navigation from "../../Navigation/VerticleNav";
import { toggleNavCollapsed } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import useStyles from "./AppSidebar.style";
import Scrollbar from "../../Scrollbar";
import AppContext from "../../../utility/AppContext";
import { renderMetadata } from "../../../../shared/helpers/helpers.js";

const AppSidebar = (props) => {
  const dispatch = useDispatch();
  const navCollapsed = useSelector(({ settings }) => settings.navCollapsed);
  const { themeMode } = useContext(AppContext);

  const handleToggleDrawer = () => {
    dispatch(toggleNavCollapsed());
  };
  const classes = useStyles({ themeMode });
  let sidebarClasses = classes.sidebarStandard;

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor={props.position}
          open={navCollapsed}
          onClose={(ev) => handleToggleDrawer()}
          classes={{
            root: clsx(classes.sidebarWrapper, props.variant),
            paper: clsx(
              classes.sidebar,
              props.variant,
              props.position === "left"
                ? classes.leftSidebar
                : classes.rightSidebar
            ),
          }}
          // container={props.rootRef.current}
          BackdropProps={{
            classes: {
              root: classes.backdrop,
            },
          }}
          style={{ position: "absolute" }}
        >
          <Box height="100%" className={classes.container}>
            <Box
              className={clsx(classes.sidebarBg, sidebarClasses)}
              style={{ height: "calc(100% - 60px)" }}
            >
              <Scrollbar className={classes.drawerScrollAppSidebar}>
                <Navigation />
              </Scrollbar>
            </Box>
            <Box
              style={{
                color: "#C0C0C8",
                background: "#515154",
                height: "58px",
              }}
            >
              {renderMetadata()}
            </Box>
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Box height="100%" className={classes.container}>
          <Box
            className={clsx(classes.sidebarBg, sidebarClasses)}
            style={{ height: "calc(100% - 60px)" }}
          >
            <Scrollbar className={classes.scrollAppSidebar}>
              <Navigation />
            </Scrollbar>
          </Box>
          <Box
            style={{
              color: "#C0C0C8",
              background: "#515154",
              height: "58px",
            }}
          >
            {renderMetadata()}
          </Box>
        </Box>
      </Hidden>
    </>
  );
};

export default AppSidebar;

AppSidebar.defaultProps = {
  variant: "",
  position: "left",
};

AppSidebar.propTypes = {
  position: PropTypes.string,
  variant: PropTypes.string,
};
