import axios from "axios";

const apiAxios = axios.create({
  baseURL: '',
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
// Use the one-shot token if it exists, else fall back to regular token
apiAxios.interceptors.request.use(function (config) {
  let ss = localStorage.getItem("sshepherdSecurity");
  let ssParsed = ss ? JSON.parse(ss) : null;

  if (
    ssParsed?.api_limit_single_use &&
    ssParsed?.api_limit_endpoints?.length > 0
  ) {
    // Use one-shot token
    let oneshotToken = localStorage.getItem("oneshotToken");
    if (oneshotToken?.length > 0) {
      config.headers["Authentication-Token"] = oneshotToken;
      localStorage.removeItem("oneshotToken");
      return config;
    }
  }

  // Use console login token
  let token = localStorage.getItem("token");
  config.headers["Authentication-Token"] = token;

  return config;
});

export default apiAxios;
