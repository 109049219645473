import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const usersConfigs = [
  {
    auth: ["admin"],
    routes: [
      {
        path: '/users',
        component: React.lazy(() => import('./Users')),
      },
    ],
  },

];
