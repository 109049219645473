import {
  CREATE_NEW_SSHEPHERD_GROUP_START,
  CREATE_NEW_SSHEPHERD_GROUP,
  //CREATE_NEW_SSHEPHERD_USER,
  DELETE_SSHEPHERD_USER,
  DELETE_SSHEPHERD_HOST,
  DELETE_SSHEPHERD_GROUP,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  //GET_TASK_LIST,
  GET_SSHEPHERD_SESSION_LIST,
  GET_SSHEPHERD_SESSION_DETAIL,
  GET_SSHEPHERD_SESSION_CHARTS,
  GET_SSHEPHERD_DASHBOARD_LIST,
  GET_SSHEPHERD_DEVICE_LIST,
  GET_SSHEPHERD_ROLE_LIST,
  GET_SSHEPHERD_USER_LIST,
  GET_SSHEPHERD_USER_ROLES,
  GET_SSHEPHERD_ROLE_USERS,
  GET_SSHEPHERD_GROUP_LIST,
  SET_SSHEPHERD_SELECTED_SESSION,
  SET_SSHEPHERD_DETAIL_SESSION_ID,
  SET_SSHEPHERD_SELECTED_GROUP,
  SET_SSHEPHERD_SELECTED_ROLE,
  SET_SSHEPHERD_SELECTED_USER,
  SET_SSHEPHERD_SELECTED_HOST,
  SET_SSHEPHERD_SELECTED_VIEWDESCRIPTION,
  SHOW_MESSAGE,
  SET_SSHEPHERD_SESSION_TIME_FILTER,
  SET_SSHEPHERD_SESSION_TIMERANGE_FILTER,
  SET_SSHEPHERD_SESSION_TYPE_FILTER,
  SET_SSHEPHERD_SESSION_LIVE_FILTER,
  ATTACH_SSHEPHERD_LIVE_SESSION,
  SSHEPHERD_LIVE_SESSION_UPDATED,
  SET_SSHEPHERD_SELECTED_SESSION_SHOW_DETAIL,
  SET_SSHEPHERD_SELECTED_SESSION_SHOW_VIDEO,
  SSHEPHERD_REFRESH_TIMER,
  GET_SSHEPHERD_AUTH_PROVIDER,
  GET_SSHEPHERD_AUTH_CONFIG,
  SET_SSHEPHERD_AUTH_CONFIG,
  SET_SSHEPHERD_DETECTING_AUTH_PROVIDER,
  //RESET_SSHEPHERD_STATE,
  //SIGNOUT_AUTH_SUCCESS,
  GET_SSHEPHERD_LICENSE_CONFIG,
  SET_SSHEPHERD_LICENSE_CONFIG,
  GET_SSHEPHERD_REGISTRATION_KEYS,
  SET_SSHEPHERD_SELECTED_REGISTRATION_KEY,
  GENERATE_SSHEPHERD_REGISTRATION_KEY,
  DELETE_SSHEPHERD_REGISTRATION_KEY,
  CLEAR_SSHEPHERD_GENERATED_REGISTRATION_KEY,
  SET_SSHEPHERD_FORCE_STANDARD_AUTH_LOGIN,
  //GET_SSHEPHERD_SECURITY,
  SSHEPHERD_AUTHORIZATION_DIALOG_OPEN,
  SSHEPHERD_AUTHORIZATION_DIALOG_CLOSE,
  SET_ONESHOT_AUTH_WINDOW,
  GET_SSHEPHERD_SELECTED_HOST_CONFIG,
  SET_EFFECTIVE_TOKEN_MAX_AGE,
  SET_SSHEPHERD_SELECTED_VIDEO_REPLAY_URL,
  SET_SSHEPHERD_IGNORE_REFRESH,
} from "../../shared/constants/ActionTypes";
import {
  authProviders,
  defaultAuthConfig,
  //ssoConfig,
  timeRangeFilter,
  OS,
} from "../../shared/constants/AppConst";
import Api from "../../@crema/services/ApiConfig";
import { appIntl } from "../../@crema/utility/Utils";
import moment from "moment";
import io from "socket.io-client";
import { onJWTAuthSignout } from "../actions";
import { showMessage, showWarning } from "../actions/Common";

import { onGetSSOToken } from "../actions/JWTAuth";

var path = require("path");

export const fetchInitialData = () => {
  return (dispatch) => {
    dispatch(onDetectAuthProvider());
    dispatch(onGetSSHepherdSecurity());
    dispatch(onGetSessionList());
    dispatch(onGetHosts());
    dispatch(onGetUsers());
    dispatch(onGetGroups());
    dispatch(onGetRoles());
    dispatch(onGetRegistrationKeys());
    dispatch(onGetLicenseConfig());
  };
};

export const onDetectAuthProvider = () => {
  return (dispatch) => {
    dispatch({ type: SET_SSHEPHERD_DETECTING_AUTH_PROVIDER, payload: true });

    Api.get("/sso", {})
      .then((result) => {
        if (result.status === 200) {
          let authType = result.data.auth_type;
          dispatch({ type: GET_SSHEPHERD_AUTH_PROVIDER, payload: authType }); // clear out any previous detail
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });

    dispatch({ type: SET_SSHEPHERD_DETECTING_AUTH_PROVIDER, payload: false });
  };
};

export const forceStandardLogin = () => {
  return (dispatch) => {
    dispatch({ type: SET_SSHEPHERD_FORCE_STANDARD_AUTH_LOGIN, payload: true });
  };
};

export const onGetSSHepherdSecurity = () => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/security", {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: SET_EFFECTIVE_TOKEN_MAX_AGE,
            payload: result.data?.effectiveTokenMaxAge,
          });
          dispatch({ type: FETCH_SUCCESS });
          localStorage.setItem(
            "sshepherdSecurity",
            JSON.stringify(result.data)
          );
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          dispatch(onJWTAuthSignout());
        }

        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const showAuthorizationDialog = (callback) => (dispatch) => {
  return dispatch({
    type: SSHEPHERD_AUTHORIZATION_DIALOG_OPEN,
    payload: callback,
  });
};

export const hideAuthorizationDialog = () => {
  return (dispatch) => {
    //console.log("Closing Authorization dialog.");
    dispatch({ type: SSHEPHERD_AUTHORIZATION_DIALOG_CLOSE, payload: null });
  };
};

export const onGetSessionList = () => {
  // Do we have a valid token, if not, ignore.  Sessions view is the default view, and
  // an attempt is made to load sessions view.  We want to avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  // Check User Permissions
  let user = null;
  let userItem = localStorage.getItem("user");
  if (userItem) user = JSON.parse(userItem);
  if (
    !user ||
    !user.effectivePermissions ||
    !user.effectivePermissions.includes("list-recording")
  )
    return (dispatch) => {};

  const { messages } = appIntl();
  let startDate = new Date("2020-01-01T00:00:00"); // All Sessions
  //let startDate = new Date();
  //startDate.setDate(startDate.getDate()-30); // 30 days ago

  return (dispatch) => {
    //dispatch({ type: FETCH_START });
    Api.get("/api/recording", {
      params: {
        start: formatDateTimeForAPI(startDate),
        end: formatDateTimeForAPI(new Date()),
      },
    })
      .then((result) => {
        if (result.status === 200) {
          if (result && result.data && result.data.recordings) {
            result.data.recordings.map((row) => {
              row.isLive = row.ended_on ? false : true;
              row.Status = row.isLive ? "Live" : "Finished";
              row.SessionType = row.has_video ? "RDP" : "SSH";
              if (row.started_on && row.started_on.slice(-1) != "Z")
                row.started_on += "Z";
              if (row.ended_on && row.ended_on.slice(-1) != "Z")
                row.ended_on += "Z";

              row.StartedOnX = moment(row.started_on).unix();
              row.EndedOnX = moment(row.ended_on).unix();
              row.FormattedStartTime = moment
                .utc(row.started_on)
                .local()
                .format("L LT");
              row.FormattedEndTime = row.ended_on
                ? moment.utc(row.ended_on).local().format("L LT")
                : "-";
              row.Duration = row.ended_on
                ? moment(row.ended_on).diff(row.started_on) // use ended time
                : moment.utc().diff(moment.utc(row.started_on)); // use time from now

              let dur = moment.duration(row.Duration);
              row.FormattedDuration =
                (row.ended_on ? "" : "+") + // show plus sign for live sessions
                ((dur.years() > 0 ? dur.years() + "Y " : "") +
                  (dur.months() > 0 ? dur.months() + "M " : "") +
                  (dur.days() > 0 ? dur.days() + "d " : "") +
                  (dur.hours() > 0 ? dur.hours() + "h " : "") +
                  (dur.minutes() > 0 ? dur.minutes() + "m " : "") +
                  dur.seconds() +
                  (row.ended_on ? "." + dur.milliseconds() : "") +
                  "s");

              row.Hostname = row.host ? row.host.hostname : null;
              row.Username = row.user ? row.user.email : null;
            });
          }

          dispatch(onCalculateSessionCharts(result.data.recordings));
          //dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_SSHEPHERD_SESSION_LIST, payload: result.data });
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetDashboardSessionList = () => {
  // Do we have a valid token, if not, ignore.  Sessions view is the default view, and
  // an attempt is made to load sessions view.  We want to avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  const { messages } = appIntl();
  //let startDate = new Date("2020-01-01T00:00:00")) // All Sessions
  let startDate = new Date();
  startDate.setDate(startDate.getDate() - 30); // 30 days ago

  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/agent/dashboard", {
      params: {
        start: formatDateTimeForAPI(startDate),
        end: formatDateTimeForAPI(new Date()),
      },
    })
      .then((result) => {
        if (result.status === 200) {
          //console.log("dashboard result: ", result);

          if (result && result.data && result.data.recordings) {
            result.data.recordings.map((row) => {
              row.isLive = row.ended_on ? false : true;
              row.Status = row.isLive ? "Live" : "Finished";
              row.SessionType = row.has_video ? "RDP" : "SSH";
              row.FormattedStartTime = moment
                .utc(row.started_on)
                .local()
                .format("L LT");
              row.FormattedEndTime = row.ended_on
                ? moment.utc(row.ended_on).local().format("L LT")
                : "-";
              row.Duration = row.ended_on
                ? moment(row.ended_on).diff(row.started_on) // use ended time
                : moment.utc().diff(moment.utc(row.started_on)); // use time from now

              let dur = moment.duration(row.Duration);
              row.FormattedDuration =
                (row.ended_on ? "" : "+") + // show plus sign for live sessions
                ((dur.years() > 0 ? dur.years() + "Y " : "") +
                  (dur.months() > 0 ? dur.months() + "M " : "") +
                  (dur.days() > 0 ? dur.days() + "d " : "") +
                  (dur.hours() > 0 ? dur.hours() + "h " : "") +
                  (dur.minutes() > 0 ? dur.minutes() + "m " : "") +
                  dur.seconds() +
                  (row.ended_on ? "." + dur.milliseconds() : "") +
                  "s");

              row.Hostname = row.host ? row.host.hostname : null;
              row.Username = row.user ? row.user.email : null;
            });
          }

          //console.log("GET_SSHEPHERD_DASHBOARD_LIST");
          //console.dir(result.data);

          dispatch({ type: FETCH_SUCCESS });
          // dispatch(onCalculateSessionCharts(result.data.recordings));
          dispatch({
            type: GET_SSHEPHERD_DASHBOARD_LIST,
            payload: result.data,
          });
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onCalculateSessionCharts = (sessions) => {
  if (!sessions || sessions.length < 1) return (dispatch) => {};

  return (dispatch) => {
    // Sort Ascending for calculation of time buckets
    let sortField = "started_on";
    let sortedSessions = sessions.sort((a, b) => {
      let result = 0;
      let asf = a instanceof Object ? a[sortField] : null;
      let bsf = b instanceof Object ? b[sortField] : null;

      if (!asf) result = -1;
      if (!bsf) result = 1;

      if (result == 0) {
        let aUpper = asf?.toUpperCase();
        let bUpper = bsf?.toUpperCase();
        if (aUpper > bUpper) result = 1;
        if (aUpper < bUpper) result = -1;
      }
      return result; // * -1; // *-1 for descending, otherwise *1 for ascending
    });

    let timeNow = moment.utc(); // use timeNow for calculating differences, but use moment() / moment.utc() elsewhere to avoid corrupting timeNow, especially using endOf()

    let past24Hours = [];
    for (let n = 0; n < 24; n++)
      past24Hours.push({
        Sessions: 0,
        Start: moment(timeNow).subtract(n + 1, "hours"),
        Label: n == 0 ? "Now" : n == 1 ? n + " hour ago" : n + " hours ago", // + moment(timeNow).subtract(n + 1, "hours").local().format("L LT"),
        Duration: "hours",
      });

    let pastWeek = [];
    for (let n = 0; n < 7; n++)
      pastWeek.push({
        Sessions: 0,
        Start: moment(timeNow).subtract(n + 1, "days"),
        Label: n == 0 ? "Today" : n == 1 ? "Yesterday" : n + " days ago", // + moment(timeNow).subtract(n, "days").local().format("L LT"),
        Duration: "days",
      });

    let pastMonth = [];
    for (let n = 0; n < 30; n++)
      pastMonth.push({
        Sessions: 0,
        Start: moment(timeNow).subtract(n + 1, "days"),
        Label: n == 0 ? "Today" : n == 1 ? "Yesterday" : n + " days ago", // + moment(timeNow).subtract(n, "days").local().format("L LT"),
        Duration: "days",
      });

    let pastAll = [];
    let firstSessionStarted = moment.utc(sortedSessions[0].started_on);
    let firstSessionStartDaysDiff = timeNow.diff(firstSessionStarted, "days");
    for (let n = 0; n <= firstSessionStartDaysDiff; n++)
      pastAll.push({
        Sessions: 0,
        Label: n == 0 ? "Today" : n == 1 ? "Yesterday" : n + " days ago",
        Start: moment(timeNow).subtract(n + 1, "days"),
        Duration: "days",
      });

    let longRunning = []; //...pastAll];
    for (let n = 0; n < pastAll.length; n++) {
      longRunning.push({ ...pastAll[n] });
    }

    let timeStart = null;
    let timeEnd = null;
    for (let h = 0; h < 24; h++) {
      timeEnd = moment(timeNow).utc().subtract(h, "hours").unix();
      timeStart = moment(timeNow)
        .utc()
        .subtract(h + 1, "hours")
        .unix();
      past24Hours[h].Sessions = sortedSessions.filter(
        (s) =>
          s.StartedOnX <= timeEnd && (s.EndedOnX >= timeStart || !s.EndedOnX)
      ).length;
    }

    for (let d = 0; d < 7; d++) {
      timeEnd = moment(timeNow).utc().subtract(d, "days").unix();
      timeStart = moment(timeNow)
        .utc()
        .subtract(d + 1, "days")
        .unix();
      pastWeek[d].Sessions = sortedSessions.filter(
        (s) =>
          s.StartedOnX <= timeEnd && (s.EndedOnX >= timeStart || !s.EndedOnX)
      ).length;
    }

    for (let d = 0; d < 30; d++) {
      timeEnd = moment(timeNow).utc().subtract(d, "days").unix();
      timeStart = moment(timeNow)
        .utc()
        .subtract(d + 1, "days")
        .unix();
      pastMonth[d].Sessions = sortedSessions.filter(
        (s) =>
          s.StartedOnX <= timeEnd && (s.EndedOnX >= timeStart || !s.EndedOnX)
      ).length;
    }

    for (let d = 0; d < pastAll.length; d++) {
      timeEnd = moment(timeNow).utc().subtract(d, "days").unix();
      timeStart = moment(timeNow)
        .utc()
        .subtract(d + 1, "days")
        .unix();
      pastAll[d].Sessions = sortedSessions.filter(
        (s) =>
          s.StartedOnX <= timeEnd && (s.EndedOnX >= timeStart || !s.EndedOnX)
      ).length;
    }

    timeStart = moment(timeNow).utc().subtract(24, "hours").unix();
    let totalPast24Hours = sortedSessions.filter(
      (s) => s.EndedOnX >= timeStart || !s.EndedOnX
    ).length;

    //timeStart = moment(timeNow).startOf('day').utc().subtract(6, 'days').format();
    timeStart = moment(timeNow).startOf("day").utc().subtract(6, "days").unix();
    let totalPastWeek = sortedSessions.filter(
      (s) => s.EndedOnX >= timeStart || !s.EndedOnX
    ).length;

    timeStart = moment(timeNow)
      .startOf("day")
      .utc()
      .subtract(29, "days")
      .unix();
    let totalPastMonth = sortedSessions.filter(
      (s) => s.EndedOnX >= timeStart || !s.EndedOnX
    ).length;

    let totalAll = sortedSessions ? sortedSessions.length : 0;

    // Long Running sessions that are still live and have a duration >= 24 hours
    let totalLongRunning = 0;
    let dayMSecs = 60 * 60 * 24 * 1000; // msecs for 24 hours
    for (let d = 0; d < longRunning.length; d++) {
      timeEnd = moment(timeNow).utc().subtract(d, "days").unix();
      timeStart = moment(timeNow)
        .utc()
        .subtract(d + 1, "days")
        .unix();
      longRunning[d].Sessions = sortedSessions.filter(
        (s) =>
          s.isLive &&
          s.Duration >= dayMSecs &&
          s.StartedOnX <= timeEnd &&
          (s.EndedOnX >= timeStart || !s.EndedOnX)
      ).length;
    }
    totalLongRunning = longRunning?.length > 0 ? longRunning[0].Sessions : 0; // only sessions that are live right now

    dispatch({
      type: GET_SSHEPHERD_SESSION_CHARTS,
      payload: {
        past24Hours: past24Hours.reverse(),
        totalPast24Hours: totalPast24Hours,
        pastWeek: pastWeek.reverse(),
        totalPastWeek: totalPastWeek,
        pastMonth: pastMonth.reverse(),
        totalPastMonth: totalPastMonth,
        pastAll: pastAll.reverse(),
        totalAll: totalAll,
        longRunning: longRunning.reverse(),
        totalLongRunning: totalLongRunning,
      },
    });
  };
};

export const onSetSessionTimeFilter = (filter) => {
  let timeFilterStart = null;
  switch (filter) {
    case timeRangeFilter.Past24Hours: // Past 24 Hours
      timeFilterStart = moment().subtract(24, "hours");
      break;

    case timeRangeFilter.PastWeek: // Past 7 Days
      timeFilterStart = moment().endOf("day").subtract(6, "days");
      break;

    case timeRangeFilter.PastMonth: // Past 30 Days
      timeFilterStart = moment().endOf("day").subtract(29, "days");
      break;

    case timeRangeFilter.All:
    case timeRangeFilter.longRunning:
    default:
      timeFilterStart = moment("2001-01-01T00:00"); // beginning of time that sessions could have been recorded with SSHepherd
  }

  localStorage.setItem("sessionTimeFilter", filter);

  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SESSION_TIME_FILTER,
      payload: {
        timeFilter: filter,
        startTime: timeFilterStart.utc().unix(),
        onlyLive: filter == timeRangeFilter.LongRunning,
      }, // 4 is long running > 24 hours
    });
  };
};

export const onSetSessionTimeRangeFilter = (startTime, duration) => {
  //let timeFilterStart = moment.utc(startTime).format();
  let timeFilterStart = moment(startTime).utc().unix();

  //moment.utc(row.ended_on).local().format("L LT")
  let timeFilterEnd = moment(startTime).add(1, duration).utc().unix();

  //localStorage.setItem("sessionTimeRangeFilter", filter);
  //console.log("timeFilterStart", timeFilterStart);
  //console.log("timeFilterEnd", timeFilterEnd);
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SESSION_TIMERANGE_FILTER,
      payload: { startTime: timeFilterStart, endTime: timeFilterEnd },
    });
  };
};

export const onSetSessionTypeFilter = (sessionType) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SESSION_TYPE_FILTER,
      payload: { sessionType: sessionType },
    });
  };
};

export const onSetSessionLiveFilter = (isLive) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SESSION_LIVE_FILTER,
      payload: { isLive: isLive },
    });
  };
};

export const onGetSessionDetail = (sessionId) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: GET_SSHEPHERD_SESSION_DETAIL, payload: null }); // clear out any previous detail
    dispatch({ type: FETCH_START });

    Api.get("/api/recording/" + sessionId + "/data", {
      params: {
        //start: formatDateTimeForAPI( new Date("2020-01-01T00:00:00") ),
        //end: formatDateTimeForAPI( new Date() )
      },
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: FETCH_SUCCESS });

          dispatch({
            type: GET_SSHEPHERD_SESSION_DETAIL,
            payload: result.data,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onSetDetailSessionId = (id) => {
  // clear detailSessionId
  return async (dispatch, getState) => {
    dispatch({
      type: SET_SSHEPHERD_DETAIL_SESSION_ID,
      payload: id,
    });
  };
};

export const onAttachLiveSession = (session) => {
  return async (dispatch, getState) => {
    //console.log("Attach to live session...");
    let token = localStorage.getItem("token");
    //console.log("console login token: ", token);
    if (isOneShotTunnel()) {
      await dispatch(promptForOneShotTokenSSO());
      token = localStorage.getItem("oneshotToken");
      //console.log("token is one-shot token", token);
    }

    //console.log("Attaching to tunnel with token: ", token);

    let liveSessionURL = Api.defaults.baseURL.replace("api/", ""); // trim off the ending
    let liveSocket = io(liveSessionURL, {
      query: { auth_token: token },
      auth: (cb) => {
        cb(token);
      },
      query: { auth_token: token },
      extraHeaders: {
        "Authentication-Token": token,
        "X-ET-Ingress-Tag": session.host.id,
      },
      //transports: ['websocket'],//, 'polling'],
    });

    // client-side
    liveSocket.on("error", (err) => {
      console.log(err); // { content: "Please retry later" }
    });

    /*
    liveSocket.onAny((event, ...args) => {
      console.log("liveSocket event: ", event);
      console.log("liveSocket event args: ", args);
    });*/

    liveSocket.on("disconnect", () => {
      //console.log("tunnel  disconnected: ", liveSocket.id);
      dispatch(onGetSessionList()); // Update status
    });

    liveSocket.on("tunnel_terminated", () => {
      //console.log("tunnel  terminated: ", liveSocket.id);
      dispatch(onGetSessionList()); // Update status
    });

    // Establish Connection
    liveSocket.on("connect", () => {
      //console.log("socket connect: ", liveSocket.id);

      let attachmentConfig = { recording_id: session.id, replay: true }; // true = replay with new data, false is just new data only
      //console.log("...Establishing new_record_attach: ", attachmentConfig);
      liveSocket.emit("new_record_attach", attachmentConfig);
    });

    //console.log("...Subscribing to 'record_out' event");
    liveSocket.on("record_out", (data) => {
      //console.log("record_out event: ", data);
      dispatch({
        type: SSHEPHERD_LIVE_SESSION_UPDATED,
        payload: { socketData: data },
      });
    });

    liveSocket.on("connect_error", (data) => {
      console.log("connect_error: ", data);
    });

    liveSocket.connect();

    dispatch({
      type: ATTACH_SSHEPHERD_LIVE_SESSION,
      payload: { sessionId: session.id, liveSessionSocket: liveSocket },
    });
  };
};

export const onGetHosts = () => {
  // Do we have a valid token, if not, ignore.
  // Avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/agent", {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          //if( result && result.data && result.data.agents ){
          //  result.data.agents.map((row) => {
          //    //row.Status = row.ended_on ? "Finished" : "Live";
          //  });
          //}

          let sortField = "hostname";
          let sortedItems = [];
          if (result.data && result.data.agents) {
            sortedItems = result.data.agents.sort((a, b) => {
              let result = 0;
              let asf = a instanceof Object ? a[sortField] : null;
              let bsf = b instanceof Object ? b[sortField] : null;

              if (!asf) result = -1;
              if (!bsf) result = 1;

              if (result == 0) {
                let aUpper = asf?.toUpperCase();
                let bUpper = bsf?.toUpperCase();
                if (aUpper > bUpper) result = 1;
                if (aUpper < bUpper) result = -1;
              }
              return result; // * -1; // use *1 or *-1 to alternate ascending and descending
            });
          }

          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_SSHEPHERD_DEVICE_LIST, payload: result.data });
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetHostConfig = (agentId, os, forcePromptCreds) => {
  // Do we have a valid token, if not, ignore.
  // Avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/api/agent/" + agentId + "/config";
    if (forcePromptCreds || isOneShotAPI("get", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }
    dispatch({ type: FETCH_START });
    Api.get(apiUrl, {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          let dat = result.data;
          dat.agentId = agentId;
          dat.config.loglevel =
            dat?.config?.loglevel != null ? dat.config.loglevel : "ERROR";
          dat.config.recording_enabled = dat?.config?.hasOwnProperty(
            "recording_enabled"
          )
            ? dat.config.recording_enabled
            : true;
          dat.config.recording_fps =
            dat?.config?.recording_fps != null ? dat.config.recording_fps : 5;
          dat.config.recording_scale =
            dat?.config?.recording_scale != null
              ? dat.config.recording_scale
              : 1;
          // convert bit rate from string of Kbps to integer that UI will use for Mbps
          let bitRate = dat.config.recording_bitrate;
          if (bitRate?.length > 0 && bitRate[bitRate.length - 1] == "k") {
            bitRate = bitRate.slice(0, -1); // drop the k from the end
            bitRate = parseInt(bitRate) / 1000;
          } else {
            bitRate = 2; // 2 Mbps is default
          }
          dat.config.recording_bitrate = bitRate;
          dat.config.whitelist =
            dat?.config?.whitelist != null
              ? dat.config.whitelist
              : os == OS.Windows
              ? [3389]
              : [];

          dispatch({ type: GET_SSHEPHERD_SELECTED_HOST_CONFIG, payload: dat });
          dispatch({ type: FETCH_SUCCESS });
        } else if (result.Status === 400 || result.Status === 401) {
          dispatch(onJWTAuthSignout());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch(
          handleError(error, () => {
            dispatch(onGetHostConfig(agentId, os, true)); // retry forcing prompt for creds
          })
        );
      });
  };
};

export const onSaveHostConfig = (agentId, config, forcePromptCreds) => {
  // Do we have a valid token, if not, ignore.
  // Avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  // Check User Permissions
  let user = null;
  let userItem = localStorage.getItem("user");
  if (userItem) user = JSON.parse(userItem);
  if (
    !user ||
    !user.effectivePermissions ||
    !user.effectivePermissions.includes("modify-agent-config")
  )
    return (dispatch) => {};

  // Prepare the data
  // Convert the whitelist from string to array
  let agentConfigToSave = { ...config };
  if (
    agentConfigToSave.whitelist &&
    !Array.isArray(agentConfigToSave.whitelist)
  ) {
    agentConfigToSave.whitelist = agentConfigToSave.whitelist.endsWith(",")
      ? agentConfigToSave.whitelist.slice(0, -1) // trim off end character
      : agentConfigToSave.whitelist;
    agentConfigToSave.whitelist = agentConfigToSave.whitelist.split(",");
  }

  // Convert Mbps float to Kbps string
  agentConfigToSave.recording_bitrate =
    (agentConfigToSave.recording_bitrate * 1000).toString() + "k";

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/api/agent/" + agentId + "/config";
    if (forcePromptCreds || isOneShotAPI("post", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }

    Api.post(apiUrl, agentConfigToSave)
      .then((result) => {
        if (result.status === 200) {
          dispatch(showMessage("Configuration saved."));

          let dat = { agentId: agentId, config: config };
          dispatch({ type: GET_SSHEPHERD_SELECTED_HOST_CONFIG, payload: dat });
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({ type: GET_SSHEPHERD_SELECTED_HOST_CONFIG, payload: {} });
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch(
          handleError(error, () => {
            dispatch(onSaveHostConfig(agentId, config, true)); // retry forcing prompt for creds
          })
        );
      });
  };
};

export const onGetRoles = () => {
  // Do we have a valid token, if not, ignore.
  // Avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  // Check User Permissions
  let user = null;
  let userItem = localStorage.getItem("user");
  if (userItem) user = JSON.parse(userItem);
  if (
    !user ||
    !user.effectivePermissions ||
    !user.effectivePermissions.includes("list-user-role")
  )
    return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/role", {
      params: {
        //start: formatDateTimeForAPI( new Date("2020-01-01T00:00:00") ),
        //end: formatDateTimeForAPI( new Date() )
      },
    })
      .then((result) => {
        if (result.status === 200) {
          /*
          if( result && result.data && result.data.recordings ){
            result.data.recordings.map((row) => {
              row.Status = row.ended_on ? "Finished" : "Live";
              row.SessionType = row.has_video ? "RDP" : "SSH";
              row.FormattedStartTime = new Date(row.started_on).toLocaleString();
              row.FormattedEndTime = row.ended_on ? new Date(row.ended_on).toLocaleString() : "-";
              row.Hostname = row.host ? row.host.hostname : null;
              row.Username = row.user ? row.user.email : null;
            });
          }
          */
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_SSHEPHERD_ROLE_LIST, payload: result.data });
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetUsers = () => {
  // Do we have a valid token, if not, ignore.
  // Avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/user", {
      params: {
        //start: formatDateTimeForAPI( new Date("2020-01-01T00:00:00") ),
        //end: formatDateTimeForAPI( new Date() )
      },
    })
      .then((result) => {
        if (result.status === 200) {
          /*
        if( result && result.data && result.data.recordings ){
          result.data.recordings.map((row) => {
            row.Status = row.ended_on ? "Finished" : "Live";
              row.SessionType = row.has_video ? "RDP" : "Terminal";
            row.FormattedStartTime = new Date(row.started_on).toLocaleString();
            row.FormattedEndTime = row.ended_on ? new Date(row.ended_on).toLocaleString() : "-";
            row.Hostname = row.host ? row.host.hostname : null;
            row.Username = row.user ? row.user.email : null;
          });
        }
        */

          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_SSHEPHERD_USER_LIST, payload: result.data });
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetGroups = () => {
  // Do we have a valid token, if not, ignore.
  // Avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  // Check User Permissions
  let user = null;
  let userItem = localStorage.getItem("user");
  if (userItem) user = JSON.parse(userItem);
  if (
    !user ||
    !user.effectivePermissions ||
    !user.effectivePermissions.includes("list-group")
  )
    return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/group", {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          /*
          if( result && result.data && result.data.groups ){
            result.data.groups.map((row) => {
              row.Status = row.ended_on ? "Finished" : "Live";
            });
          } */
          let sortField = "name";
          let sortedGroups = [];
          if (result.data && result.data.groups) {
            sortedGroups = result.data.groups.sort((a, b) => {
              let result = 0;
              let asf = a instanceof Object ? a[sortField] : null;
              let bsf = b instanceof Object ? b[sortField] : null;

              if (!asf) result = -1;
              if (!bsf) result = 1;

              if (result == 0) {
                let aUpper = asf?.toUpperCase();
                let bUpper = bsf?.toUpperCase();
                if (aUpper > bUpper) result = 1;
                if (aUpper < bUpper) result = -1;
              }
              return result; // * -1; // use *1 or *-1 to alternate ascending and descending
            });
          }

          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_SSHEPHERD_GROUP_LIST,
            payload: { groups: sortedGroups },
          });
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onAddGroup = (groupName, forcePromptCreds) => {
  if (!groupName) return (dispatch) => {};

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/api/group";
    if (forcePromptCreds || isOneShotAPI("post", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }
    dispatch({ type: CREATE_NEW_SSHEPHERD_GROUP_START });
    Api.post(apiUrl, {
      name: groupName,
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: CREATE_NEW_SSHEPHERD_GROUP, payload: result.data });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["group.created"],
          });

          dispatch(onGetGroups());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch(
          handleError(
            error,
            () => {
              dispatch(onAddGroup(groupName, true)); // retry forcing prompt for creds
            },
            () => {
              console.log("error: ", error);
              if (error?.response?.data?.error?.includes("E11000")) {
                alert(
                  "That group name is already in use.  Please use a different name."
                );
              }
              dispatch({ type: FETCH_ERROR, payload: error.message });
            }
          )
        );
      });
  };
};

export const onDeleteGroup = (group, forcePromptCreds) => {
  if (!group.name) return (dispatch) => {};

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/api/group/" + group.name;
    if (forcePromptCreds || isOneShotAPI("delete", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }

    dispatch({ type: FETCH_START });
    Api.delete(apiUrl, {
      //params: {
      //},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: DELETE_SSHEPHERD_GROUP, payload: result.data });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["group.Deleted"],
          });

          dispatch(onGetGroups());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch(
          handleError(error, () => {
            dispatch(onDeleteGroup(group, true)); // retry forcing prompt for creds
          })
        );
      });
  };
};

export const onAddUser = (user, forcePromptCreds) => {
  if (!user.email && !user.password) return (dispatch) => {};

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/api/user"; // + user.email;
    if (forcePromptCreds || isOneShotAPI("post", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }

    dispatch({ type: FETCH_START });
    Api.post(apiUrl, {
      email: user.email,
      password: user.password,
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["user.created"],
          });

          dispatch(onGetUsers());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch(
          handleError(
            error,
            () => {
              dispatch(onAddUser(user, true)); // retry forcing prompt for creds
            },
            () => {
              console.log("error: ", error);
              if (
                error.response &&
                error.response.data &&
                error.response.data.error &&
                error.response.data.error.includes("E11000")
              ) {
                alert("The user you are trying to add has already been added.");
              }
              dispatch({ type: FETCH_ERROR, payload: error.message });
            }
          )
        );
      });
  };
};

export const onEditUser = (user, forcePromptCreds) => {
  if (!user.password || !user.newPassword || !user.newPasswordConfirm)
    return (dispatch) => {};

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/api/user/password";
    if ((forcePromptCreds, isOneShotAPI("post", apiUrl))) {
      await dispatch(promptForOneShotTokenSSO());
    }
    dispatch({ type: FETCH_START });
    Api.post(apiUrl, {
      password: user.password,
      new_password: user.newPassword,
      new_password_confirm: user.newPasswordConfirm,
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["user.Saved"],
          });

          dispatch(onGetUsers());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch(
          handleError(
            error,
            () => {
              dispatch(onEditUser(user, true)); // retry forcing prompt for creds
            },
            () => {
              //dispatch({ type: FETCH_ERROR, payload: error.message });
              try {
                if (error.response.data.response.errors.password)
                  error.response.data.response.errors.password.forEach((pe) => {
                    dispatch({ type: SHOW_MESSAGE, payload: pe });
                  });
                else
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: messages["message.somethingWentWrong"],
                  });
              } catch (ex) {
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: messages["message.somethingWentWrong"],
                });
              }
            }
          )
        );
      });
  };
};

export const onDeleteUser = (user, forcePromptCreds) => {
  if (!user.email) return (dispatch) => {};

  console.log(
    "token before prompt for one shot SSO token",
    localStorage.getItem("token")
  );
  const { messages } = appIntl();

  return async (dispatch, getState) => {
    let apiUrl = "/api/user/" + user.email;
    if (forcePromptCreds || isOneShotAPI("delete", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }

    dispatch({ type: FETCH_START });
    Api.delete(apiUrl, {
      //params: {
      //},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: DELETE_SSHEPHERD_USER, payload: result.data });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["user.Deleted"],
          });

          dispatch(onGetUsers());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch(
          handleError(error, () => {
            dispatch(onDeleteUser(user, true)); // retry forcing prompt for creds
          })
        );
      });
  };
};

export const onAssignUsersToGroup = (users, group, forcePromptCreds) => {
  if (!users || users.length < 1 || !group || !group.name)
    return (dispatch) => {};

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/api/group/" + group.name + "/users";
    if (forcePromptCreds || isOneShotAPI("put", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }
    dispatch({ type: CREATE_NEW_SSHEPHERD_GROUP_START });
    dispatch({ type: FETCH_START });
    Api.put(apiUrl, {
      users: users,
    })
      .then((result) => {
        if (result.status === 200) {
          //dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["user.AddedToGroup"],
          });

          dispatch(onGetGroups());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch(
          handleError(error, () => {
            dispatch(onAssignUsersToGroup(users, group, true)); // retry forcing prompt for creds
          })
        );
      });
  };
};

export const onRemoveUserFromGroup = (user, group, forcePromptCreds) => {
  if (!user || !user.email || !group || !group.name) return (dispatch) => {};

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/api/group/" + group.name + "/user/" + user.email;
    if (forcePromptCreds || isOneShotAPI("delete", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }
    dispatch({ type: FETCH_START });
    /*Api.delete('/api/user/' + user.email, {
      email: user.email,
    })*/
    Api.delete(apiUrl, {
      //params: {
      //},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["user.RemovedFromGroup"],
          });

          dispatch(onGetGroups());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch(
          handleError(error, () => {
            dispatch(onRemoveUserFromGroup(user, group, true)); // retry forcing prompt for creds
          })
        );
      });
  };
};

export const onDeleteHost = (host, forcePromptCreds) => {
  if (!host.id) return (dispatch) => {};

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/api/agent/" + host.id;
    if (forcePromptCreds || isOneShotAPI("delete", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }

    dispatch({ type: FETCH_START });
    Api.delete(apiUrl, {
      //params: {
      //},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: DELETE_SSHEPHERD_HOST, payload: result.data });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["host.Deleted"],
          });

          dispatch(onGetHosts());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch(
          handleError(error, () => {
            dispatch(onDeleteHost(host, true)); // retry forcing prompt for creds
          })
        );
      });
  };
};

export const onAssignAgentsToGroup = (agentIds, group, forcePromptCreds) => {
  if (agentIds == null || agentIds.length < 1 || !group || !group.name)
    return (dispatch) => {};

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/api/group/" + group.name + "/agents";
    if (forcePromptCreds || isOneShotAPI("put", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }
    dispatch({ type: FETCH_START });
    Api.put(apiUrl, {
      agents: agentIds,
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["host.AddedToGroup"],
          });

          dispatch(onGetGroups());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch(
          handleError(error, () => {
            dispatch(onAssignAgentsToGroup(agentIds, group, true)); // retry forcing prompt for creds
          })
        );
      });
  };
};

export const onRemoveAgentFromGroup = (agent, group, forcePromptCreds) => {
  if (!agent || !agent.id || !group || !group.name) return (dispatch) => {};

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/api/group/" + group.name + "/agent/" + agent.id;
    if (forcePromptCreds || isOneShotAPI("delete", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }
    dispatch({ type: FETCH_START });
    Api.delete(apiUrl, {
      //params: {
      //},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["host.RemovedFromGroup"],
          });

          dispatch(onGetGroups());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch(
          handleError(error, () => {
            dispatch(onRemoveAgentFromGroup(agent, group, true)); // retry forcing prompt for creds
          })
        );
      });
  };
};

export const onGetUserRoles = (email) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/user/" + email + "/role", {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: FETCH_SUCCESS });

          /*
          if( result && result.data && result.data.roles ){
            result.data.roles.map((row) => {
              //row.Status = row.ended_on ? "Finished" : "Live";
            });
          } */

          dispatch({
            type: GET_SSHEPHERD_USER_ROLES,
            payload: { userEmail: email, userRoles: result.data },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetRoleUsers = (rolename) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/role/" + rolename, {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: FETCH_SUCCESS });

          // walk through each user in the array and build a new array having email property (for UI binding)
          let formattedResult = [];
          if (
            result &&
            result.data &&
            result.data.role &&
            result.data.role.users
          ) {
            result.data.role.users.map((row) => {
              formattedResult.push({ email: row });
            });
          }

          dispatch({
            type: GET_SSHEPHERD_ROLE_USERS,
            payload: { rolename: rolename, roleUsers: formattedResult },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onAssignUsersToRole = (users, role, forcePromptCreds) => {
  if (!users || users.length < 1 || !role || !role.name)
    return (dispatch) => {};

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/api/role/" + role.name + "/users";
    if (forcePromptCreds || isOneShotAPI("put", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }
    dispatch({ type: FETCH_START });
    Api.put(apiUrl, {
      users: users,
    })
      .then((result) => {
        if (result.status === 200) {
          //dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["user.AddedToGroup"],
          });

          dispatch(onGetRoleUsers(role.name));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch(
          handleError(
            error,
            () => {
              dispatch(onAssignUsersToRole(users, role, true)); // retry forcing prompt for creds
            },
            () => {
              console.log("error: ", error);
              if (
                error.response &&
                error.response.data &&
                error.response.data.error &&
                error.response.data.error.includes("E11000")
              ) {
                alert(
                  "The user you are trying to include has already been included."
                );
              }
              dispatch({ type: FETCH_ERROR, payload: error.message });
            }
          )
        );
      });
  };
};

export const onRemoveUserFromRole = (user, role, forcePromptCreds) => {
  if (!user || !user.email || !role || !role.name) return (dispatch) => {};

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/api/user/" + user.email + "/role/" + role.name;
    if (forcePromptCreds || isOneShotAPI("delete", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }
    dispatch({ type: FETCH_START });
    Api.delete(apiUrl, {
      //params: {
      //},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["user.RemovedFromGroup"],
          });

          dispatch(onGetRoleUsers(role.name));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch(
          handleError(error, () => {
            dispatch(onRemoveUserFromRole(user, role, true)); // retry forcing prompt for creds
          })
        );
      });
  };
};

export const onTerminateSession = (session, forcePromptCreds) => {
  if (!session || !session.host || !session.host.id) return (dispatch) => {};

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/api/tunnel/" + session.id;
    if (forcePromptCreds || isOneShotAPI("delete", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }
    dispatch({ type: FETCH_START });

    Api.delete(apiUrl, {
      headers: {
        //'Authentication-Token': token,
        "X-ET-Ingress-Tag": session.host.id,
      },
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["session.SessionTerminated"],
          });

          dispatch(onGetSessionList());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch(
          handleError(error, () => {
            dispatch(onTerminateSession(session, true)); // retry forcing prompt for creds
          })
        );
      });
  };
};

const formatDateTimeForAPI = (dateTime) => {
  //return dateTime != null ? dateTime.toISOString().replace('Z', '') : null;
  return dateTime != null
    ? new Date(dateTime).toISOString().replace("Z", "")
    : null;
};

export const onSetSelectedSession = (session) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_SESSION,
      payload: session,
    });
  };
};

export const onShowSelectedSessionDetail = (show) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_SESSION_SHOW_DETAIL,
      payload: show,
    });
  };
};

export const onShowSelectedSessionVideo = (show) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_SESSION_SHOW_VIDEO,
      payload: show,
    });
  };
};

export const onSetSelectedGroup = (group) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_GROUP,
      payload: group,
    });
  };
};

export const onSetSelectedRole = (role) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_ROLE,
      payload: role,
    });
  };
};

export const onSetSelectedUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_USER,
      payload: user,
    });
  };
};

export const onSetSelectedHost = (host) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_HOST,
      payload: host,
    });
  };
};

export const onSetSelectedViewDescription = (viewDescription) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_VIEWDESCRIPTION,
      payload: viewDescription,
    });
  };
};

export const onSetSelectedRegistrationKey = (token) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_REGISTRATION_KEY,
      payload: token,
    });
  };
};

export const onSetSelectedReplayUrl = (session) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_SELECTED_VIDEO_REPLAY_URL,
      payload: session,
    });
  };
};

export const onGetSSOConfig = () => {
  const { messages } = appIntl();
  return (dispatch) => {
    //dispatch({ type: GET_SSHEPHERD_AUTH_CONFIG, payload: defaultAuthConfig }); // clear out any previous detail
    dispatch({ type: FETCH_START });

    let authConfig = null;
    Api.get("/sso/config", {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          authConfig = result.data;

          // use defaults if no values yet
          let ss = Object.keys(authConfig?.saml_config);
          if (Object.entries(authConfig?.saml_config).length < 1) {
            // saml_config has no properties, use defaults
            console.log(
              "setting default saml config",
              defaultAuthConfig.saml_config
            );
            authConfig.saml_config = defaultAuthConfig.saml_config;
          }
          if (Object.keys(authConfig?.oidc_config).length < 1) {
            // oidc_config has no properties, use defaults
            console.log(
              "setting default oidc config",
              defaultAuthConfig.oidc_config
            );
            authConfig.oidc_config = defaultAuthConfig.oidc_config;
          }
          if (Object.keys(authConfig?.ldap_config).length < 1) {
            // ldap_config has no properties, use defaults
            console.log(
              "setting default ldap config",
              defaultAuthConfig.ldap_config
            );
            authConfig.ldap_config = defaultAuthConfig.ldap_config;
          }

          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_SSHEPHERD_AUTH_CONFIG, payload: authConfig });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onSaveAuthConfig = (authConfig, forcePromptCreds) => {
  console.log("onSaveAuthConfig: full config", authConfig);

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/sso/config";
    if (forcePromptCreds || isOneShotAPI("post", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }
    //dispatch({ type: SET_SSHEPHERD_AUTH_CONFIG, payload: defaultAuthConfig }); // clear out any previous detail

    dispatch({ type: FETCH_START });
    Api.post(apiUrl, authConfig)
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SET_SSHEPHERD_AUTH_CONFIG,
            payload: authConfig,
          });
          dispatch(onGetSSOConfig()); // refresh
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch(
          handleError(error, () => {
            dispatch(onSaveAuthConfig(authConfig, true)); // retry forcing prompt for creds
          })
        );
      });
  };
};

export const onGetLicenseConfig = () => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: GET_SSHEPHERD_LICENSE_CONFIG, payload: {} }); // clear out any previous detail
    dispatch({ type: FETCH_START });

    let licenseConfig = null;
    Api.get("/api/license", {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          licenseConfig = result.data;
          let expiration = (licenseConfig.expiresFormatted = moment
            .utc(licenseConfig.expires)
            .local());
          licenseConfig.expiresFormatted = expiration.format("L LT");
          licenseConfig.isExpired = expiration.isBefore(moment());
          licenseConfig.daysUntilExpired = moment(expiration).diff(
            moment(),
            "days"
          );
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_SSHEPHERD_LICENSE_CONFIG,
            payload: licenseConfig,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onSaveLicenseConfig = (licenseConfig, forcePromptCreds) => {
  console.log("onSaveLicenseConfig: ", licenseConfig);

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/api/license";
    if (forcePromptCreds || isOneShotAPI("post", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }
    dispatch({ type: SET_SSHEPHERD_LICENSE_CONFIG, payload: {} }); // clear out any previous detail
    //dispatch({ type: FETCH_START });

    //let authConfig = null;
    Api.post(apiUrl, licenseConfig)
      .then((result) => {
        if (result.status === 200) {
          //dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SET_SSHEPHERD_LICENSE_CONFIG,
            payload: licenseConfig,
          });
          dispatch(onGetLicenseConfig()); // refresh
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch(
          handleError(error, () => {
            dispatch(onSaveLicenseConfig(licenseConfig, true)); // retry forcing prompt for creds
          })
        );
      });
  };
};

export const onGetRegistrationKeys = () => {
  // Do we have a valid token, if not, ignore.
  // Avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  // Check User Permissions
  let user = null;
  let userItem = localStorage.getItem("user");
  if (userItem) user = JSON.parse(userItem);
  if (!user || !user.role || !user.role.includes("admin"))
    return (dispatch) => {};

  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/apikey", {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          let rows = result.data?.keys;
          rows.forEach((row) => {
            row.FormattedCreationTimestamp = moment
              .utc(row.created)
              .local()
              .format("L LT");
          });

          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_SSHEPHERD_REGISTRATION_KEYS,
            payload: rows,
          });
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGenerateRegistrationKey = (
  comment,
  permissions,
  forcePromptCreds
) => {
  // Do we have a valid token, if not, ignore.
  // Avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  // Check User Permissions
  let user = null;
  let userItem = localStorage.getItem("user");
  if (userItem) user = JSON.parse(userItem);
  if (!user || !user.role || !user.role.includes("admin"))
    return (dispatch) => {};

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/api/apikey";
    if (forcePromptCreds || isOneShotAPI("post", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }
    dispatch({ type: FETCH_START });
    Api.post(apiUrl, {
      params: { description: comment, permissions: permissions },
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GENERATE_SSHEPHERD_REGISTRATION_KEY,
            payload: result.data,
          });
          dispatch(onGetRegistrationKeys()); // refresh the list
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch(
          handleError(error, () => {
            dispatch(onGenerateRegistrationKey(comment, permissions, true)); // retry forcing prompt for creds
          })
        );
      });
  };
};

export const onDeleteRegistrationToken = (uuid, forcePromptCreds) => {
  // Do we have a valid token, if not, ignore.
  // Avoid a 403 when user is not logged in (no token)
  if (!localStorage.getItem("token"))
    return (dispatch) => {
      dispatch(onJWTAuthSignout());
    };

  // Check User Permissions
  let user = null;
  let userItem = localStorage.getItem("user");
  if (userItem) user = JSON.parse(userItem);
  if (!user || !user.role || !user.role.includes("admin"))
    return (dispatch) => {};

  const { messages } = appIntl();
  return async (dispatch, getState) => {
    let apiUrl = "/api/apikey/" + uuid;
    if (forcePromptCreds || isOneShotAPI("delete", apiUrl)) {
      await dispatch(promptForOneShotTokenSSO());
    }
    dispatch({ type: FETCH_START });
    Api.delete(apiUrl, {
      params: {},
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: DELETE_SSHEPHERD_REGISTRATION_KEY,
            payload: result.data.keys,
          });
          dispatch(onGetRegistrationKeys()); // refresh the list
        } else {
          if (result.Status === 400 || result.Status === 401) {
            dispatch(onJWTAuthSignout());
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: messages["message.somethingWentWrong"],
            });
          }
        }
      })
      .catch((error) => {
        dispatch(
          handleError(error, () => {
            dispatch(onDeleteRegistrationToken(uuid, true)); // retry forcing prompt for creds
          })
        );
      });
  };
};

export const setIgnoreRefresh = (ignore) => {
  return (dispatch) => {
    dispatch({
      type: SET_SSHEPHERD_IGNORE_REFRESH,
      payload: ignore,
    });
  };
};

export const onRefreshTimerElapsed = () => {
  return (dispatch, getState) => {
    const st = getState();
    if (!st?.sshepApp?.ignoreRefresh) {
      dispatch({
        type: SSHEPHERD_REFRESH_TIMER,
        payload: null,
      });
    }
  };
};

export const clearGeneratedRegistrationKey = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SSHEPHERD_GENERATED_REGISTRATION_KEY,
      payload: null,
    });
  };
};

export const clearCookies = () => {
  console.log("clearCookies");
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var spcook = cookies[i].split("=");
    deleteCookie(spcook[0]);
  }
  function deleteCookie(cookiename) {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    var expires = ";expires=" + d;
    var name = cookiename;
    //alert(name);
    var value = "";
    document.cookie = name + "=" + value + expires + "; path=/acc/html";
  }
  if (cookies.length < 1 || (cookies.length == 1 && cookies[0] == "")) {
  } else window.location = ""; // TO REFRESH THE PAGE
};

export const setOneShotAuthWindow = (authWin) => {
  console.log("setting oneshot auth window: ", authWin);
  return (dispatch) => {
    dispatch({
      type: SET_ONESHOT_AUTH_WINDOW,
      payload: authWin,
    });
  };
};

export const promptForOneShotTokenSSO =
  () => async (dispatch, getState) => /*async dispatch =>*/ {
    // Prompt for SSO One-Shot Token...
    const st = getState();
    let authProvider = st?.sshepApp?.authProvider;

    return await new Promise((resolve, reject) => {
      let popupWin = null;

      switch (authProvider) {
        case authProviders.PASSWORD:
        case authProviders.LDAP:
          return dispatch(
            showAuthorizationDialog((success) => {
              if (success) {
                resolve();
              } else {
                reject();
              }

              return;
            })
          );
          break;

        case authProviders.SAML: {
          let newLoc = path.join(Api.defaults.baseURL, "saml?oneshot=true");
          popupWin = popupWindow(newLoc, "Authorize", window, 500, 500);
          break;
        }

        case authProviders.OIDC: {
          let newLoc = path.join(Api.defaults.baseURL, "oidc?oneshot=true");
          popupWin = popupWindow(newLoc, "Authorize", window, 500, 500);
          break;
        }

        default:
          console.log(
            "Prompt for SSO One-Shot Token - Unhandled authProvider: ",
            authProvider
          );
      }

      if (popupWin && !popupWin.closed) {
        let timer = setInterval(() => {
          if (popupWin.closed) {
            clearInterval(timer);

            // Prompt for SSO One-Shot Token - One-Shot Auth window closed, resolving promise."
            resolve(popupWin);
          }
        }, 250); // check for window closed every quarter second
      } else {
        reject(); // Prompt for SSO One-Shot Token - reject promise
      }
    });
    //}
  };

function popupWindow(url, windowName, win, w, h) {
  //console.log("popupWindow", { url, windowName, win, w, h });
  const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
  const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
  return win.open(
    url,
    windowName,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
  );
}

export function isOneShotAPI(method, url) {
  let ss = localStorage.getItem("sshepherdSecurity");

  let ssParsed = ss ? JSON.parse(ss) : null;
  let apiMethod = method?.replace("'", "").toUpperCase();

  if (
    ssParsed?.api_limit_single_use &&
    ssParsed?.api_limit_endpoints?.length > 0
  ) {
    let found = ssParsed?.api_limit_endpoints.find((e) => {
      return (
        e.method?.toUpperCase().includes(apiMethod) &&
        RegExp(e?.path?.replaceAll("(?P<", "(?<")).test(url)
      ); // convert from python regex to js regex
    });

    return found != null;
  }
  return false;
}

export const isPlaybackAvailable = async (id) => {
  return await Api.get("/api/recording/" + id + "/playback_available", {})
    .then((result) => {
      if (result.status === 200) {
        return result.data?.available;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

function handleError(error, callback401, callbackOther) {
  return (dispatch) => {
    if (
      error.response?.status == 401 ||
      error.response?.code == 401 /*&& isFreshnessLimited()*/
    ) {
      let msg = "Authorization needed";
      //if( error.response.data?.reason )
      //  msg += " - " + error.response.data.reason;
      dispatch(showWarning(msg));
      if (callback401) {
        callback401();
      }
    } else if (callbackOther) {
      callbackOther();
    } else {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };
}

function isFreshnessLimited() {
  let ss = localStorage.getItem("sshepherdSecurity");
  let ssParsed = ss ? JSON.parse(ss) : null;
  return ssParsed?.api_limit_freshness == true;
}

function isOneShotTunnel() {
  let ss = localStorage.getItem("sshepherdSecurity");
  let ssParsed = ss ? JSON.parse(ss) : null;
  return ssParsed?.api_limit_single_use == true;
}
