import React from "react";
import { authRole } from "../../shared/constants/AppConst";

export const registrationKeysConfigs = [
  {
    auth: ["admin"],
    routes: [
      {
        path: "/registrationKeys",
        component: React.lazy(() => import("./RegistrationKeys")),
      },
    ],
  },
];
