import React from "react";
import { MessageView } from "../../../@crema";
import { useSelector } from "react-redux";

const InfoView = () => {
  const { error, loading, message, warning } = useSelector(
    ({ common }) => common
  );

  const showMessage = () => {
    return <MessageView variant="success" message={message.toString()} />;
  };

  const showWarning = () => {
    return <MessageView variant="warning" message={warning.toString()} />;
  };

  const showError = () => {
    return <MessageView variant="error" message={error.toString()} />;
  };

  return (
    <>
      {message && showMessage()}
      {warning && showWarning()}
      {error && showError()}
    </>
  );
};

export default InfoView;
